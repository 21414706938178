// src/components/ManagersTab.js
import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  Typography,
} from '@mui/material';
import DataGridStyled from '../common/DataGridStyled';
import ExportButton from '../common/ExportButton';
import useManagers from '../../hooks/useManagers';

/**
 * ManagersTab Component
 *
 * Renders a tab displaying a list of managers with their respective details.
 * Handles loading states and error scenarios during data fetching.
 * Utilizes a data grid to present manager information and provides an option to export the data to an Excel file.
 *
 * Hooks and Components:
 * - useManagers: Custom hook to retrieve manager data, loading status, and error information.
 * - useMemo: Optimizes the definition of data grid columns to prevent unnecessary re-creations on each render.
 * - CircularProgress: Displays a loading indicator while data is being fetched.
 * - Alert: Shows error messages if data fetching fails.
 * - DataGridStyled: Customized data grid component to display managers.
 * - ExportButton: Allows exporting the managers' data to an Excel file.
 *
 * @component
 * @returns {JSX.Element} The rendered ManagersTab component.
 */


const ManagersTab = () => {
  const {
    managers,
    loadingManagers,
    error,
  } = useManagers();

  // Define columns using useMemo to prevent re-creation on every render
  const managerColumns = useMemo(() => [
    { field: 'displayName', headerName: 'Namn', width: 200 },
    { field: 'mail', headerName: 'E-post', width: 250 },
    { field: 'jobTitle', headerName: 'Befattning', width: 200 },
  ], []);

  const renderContent = () => {
    if (loadingManagers) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      );
    }
    if (error) {
      return (
        <Alert severity="error" sx={{ mb: 2 }}>
          Misslyckades att ladda chefer.
        </Alert>
      );
    }
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGridStyled
          rows={managers}
          columns={managerColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          getRowId={(row) => row.id}
          disableSelectionOnClick
        />
      </div>
    );
  };

  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>Chefer</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <ExportButton
          data={managers}
          filename="Chefer.xlsx"
          label="Exportera till Excel"
        />
      </Box>
      {renderContent()}
    </>
  );
};

export default ManagersTab;
