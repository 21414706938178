/**
 * useManagers Hook
 * 
 * This custom React hook fetches a list of managers from the Microsoft Graph API based on a specific extension attribute.
 * It manages loading and error states and provides the fetched data to components that utilize the hook.
 * 
 * **Features:**
 * - **Data Fetching**: Retrieves users with the `extensionAttribute2` equal to `'Attestant'` using the Microsoft Graph API.
 * - **Authentication**: Utilizes MSAL to acquire an access token silently for authenticating API requests.
 * - **State Management**: Provides `managers`, `loadingManagers`, and `error` states to indicate the status of the data fetch operation.
 * - **Refetch Capability**: Exposes the `fetchManagers` function to allow manual refetching of data when necessary.
 * 
 * **Usage:**
 * Import and invoke the `useManagers` hook within your functional components to access the list of managers and related states.
 * 
 * **Example:**
 * ```jsx
 * import React from 'react';
 * import useManagers from '../hooks/useManagers';
 * 
 * const ManagersList = () => {
 *   const { managers, loadingManagers, error, fetchManagers } = useManagers();
 * 
 *   if (loadingManagers) {
 *     return <div>Loading...</div>;
 *   }
 * 
 *   if (error) {
 *     return <div>Error loading managers.</div>;
 *   }
 * 
 *   return (
 *     <ul>
 *       {managers.map((manager) => (
 *         <li key={manager.id}>{manager.displayName}</li>
 *       ))}
 *     </ul>
 *   );
 * };
 * 
 * export default ManagersList;
 * ```
 * 
 * **Dependencies:**
 * - React hooks: `useState`, `useEffect`, `useCallback`
 * - MSAL React hook: `useMsal` from `@azure/msal-react`
 * - Authentication config: `loginRequest` from [`src/authConfig.js`](src/authConfig.js)
 * - Microsoft Graph API service: `getUsersByExtensionAttribute2` from [`src/services/graphApiService.js`](src/services/graphApiService.js)
 * 
 * **Notes:**
 * - The hook assumes that the user is authenticated and that the MSAL context is properly set up.
 * - The `extensionAttribute2` value `'Attestant'` is used to filter users who are managers.
 * - Error handling is implemented to catch and log any issues during the token acquisition or data fetching process.
 */

import { useState, useEffect, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { getUsersByExtensionAttribute2 } from '../services/graphApiService';

const useManagers = () => {
  const [managers, setManagers] = useState([]);
  const [loadingManagers, setLoadingManagers] = useState(false);
  const [error, setError] = useState(false);
  const { instance, accounts } = useMsal();

  const fetchManagers = useCallback(async () => {
    setLoadingManagers(true);
    setError(false);
    try {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      const accessToken = response.accessToken;
      const users = await getUsersByExtensionAttribute2(accessToken, 'Attestant');
      setManagers(users);
    } catch (err) {
      console.error('Error fetching Managers:', err);
      setError(true);
    } finally {
      setLoadingManagers(false);
    }
  }, [instance, accounts]);

  useEffect(() => {
    fetchManagers();
  }, [instance, accounts, fetchManagers]);

  return { managers, loadingManagers, error, fetchManagers };
};

export default useManagers;
