// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import theme from './theme';
import { initializeTeamsContext, isRunningInTeams } from './teamsSetup';
import StartPage from './components/StartPage';
import { OrderEquipment } from './components/equipment/EquipmentComponents';
import TeamSelectionPage from './components/teams/TeamSelectionPage';
import { TeamForm } from './components/teams/TeamsComponents';
import AdminPage from './components/admin/AdminPage';
import ProtectedRoute from './components/ProtectedRoute';
import { loginRequest } from './authConfig';

const App = () => {
  useEffect(() => {
    const setupTeams = async () => {
      if (isRunningInTeams()) {
        const context = await initializeTeamsContext();
        if (context) {
          console.log('Teams context initialized:', context);
        }
      }
    };

    setupTeams();
  }, []);

  // Conditionally render auth template based on whether we're in Teams
  const renderContent = () => {
    const routes = (
      <Routes>
        <Route path="/" element={<StartPage />} />
        <Route path="/order-equipment" element={<OrderEquipment />} />
        <Route path="/create-team" element={<TeamSelectionPage />} />
        <Route path="/create-team/:teamType" element={<TeamForm />} />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute requiredGroup="AAD-Orderforms-admin">
              <AdminPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );

    // If running in Teams, skip MSAL authentication template
    if (isRunningInTeams()) {
      return routes;
    }

    // Otherwise, wrap with MSAL authentication
    return (
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        {routes}
      </MsalAuthenticationTemplate>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {renderContent()}
      </Router>
    </ThemeProvider>
  );
};

export default App;