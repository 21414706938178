// src/teamsSetup.js

import * as microsoftTeams from "@microsoft/teams-js";

export const initializeTeamsContext = async () => {
  try {
    // Initialize the Teams SDK
    await microsoftTeams.initialize();
    
    // Get the Teams context
    const context = await microsoftTeams.getContext();
    
    return context;
  } catch (error) {
    console.error('Failed to initialize Teams context:', error);
    return null;
  }
};

export const isRunningInTeams = () => {
  return window.parent !== window.self;
};