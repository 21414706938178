// src/components/AdminPage.js
import React, { useState } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import theme from '../../theme';
import OrderHistoryTab from './OrderHistoryTab';
import ManagersTab from './ManagersTab';
import PhoneManagementTab from './PhoneManagementTab';

const AdminPage = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return <OrderHistoryTab />;
      case 1:
        return <ManagersTab />;
      case 2:
        return <PhoneManagementTab />;
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, sm: 4 },
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            sx={{ color: theme.palette.primary.main, fontWeight: 700 }}
          >
            Admin Dashboard
          </Typography>
        </Box>

        <Tabs value={tabValue} onChange={handleTabChange} centered sx={{ mb: 2 }}>
          <Tab label="Orderhistorik" />
          <Tab label="Chefer/Behöriga beställare" />
          <Tab label="Telefoni" />
        </Tabs>

        {renderContent()}
      </Paper>
    </Container>
  );
};

export default AdminPage;