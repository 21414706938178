// src/services/orderService.js
/**
 * Order Service Module
 * 
 * This service module provides functionality to interact with the order history Logic App backend.
 * It handles HTTP requests and response processing for order-related operations.
 * 
 * **Features:**
 * - **Order History Retrieval**: Fetches order history data from the Azure Logic App endpoint
 * - **Error Handling**: Comprehensive error catching and logging for API communication
 * - **Response Processing**: Validates and processes API responses
 * 
 * **Core Functions:**
 * - `getOrderHistory()`: Retrieves the complete order history
 * 
 * **API Communication:**
 * - Uses fetch API for HTTP requests
 * - Communicates with a specified Azure Logic App endpoint
 * - Handles both successful and error responses
 * 
 * **Usage:**
 * ```javascript
 * import { getOrderHistory } from './orderService';
 * 
 * // Fetch order history
 * const orders = await getOrderHistory();
 * ```
 * 
 * **Error Handling:**
 * - Network errors are caught and logged
 * - HTTP error responses (non-200) trigger error messages
 * - All errors are propagated to the caller for handling
 * 
 * **Dependencies:**
 * - Requires a configured Logic App endpoint URL
 * - Uses native fetch API for HTTP requests
 */
const LOGIC_APP_URL = 'https://prod-28.swedencentral.logic.azure.com:443/workflows/c378090363d1471abf237a07632de7e2/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=WlA_2FYOfpdk4i7CLO3YoW90hUnJIGbZxmFnvdxHLw8'; // Replace with your actual Logic App URL

/**
 * Fetch order history by calling the Logic App
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of order objects.
 */
const getOrderHistory = async () => {
  try {
    const response = await fetch(LOGIC_APP_URL, {
      method: 'GET', // Assuming it's a GET request
      headers: {
        'Content-Type': 'application/json',
        // Add any required headers, such as authentication tokens or API keys
        // 'Authorization': `Bearer ${your_token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data; // Assuming data is returned in an array format
    } else {
      console.error('Error fetching data from Logic App:', response.status, response.statusText);
      throw new Error('Failed to fetch order history from Logic App');
    }
  } catch (error) {
    console.error('Error in getOrderHistory:', error);
    throw error;
  }
};

export { getOrderHistory };
