/**
 * OrderSuccessPage Component
 * 
 * This component displays a confirmation message to the user after they have successfully submitted an equipment order.
 * It informs the user that their order has been received and provides an option to return to the homepage.
 * 
 * **Features:**
 * - Displays a success alert with a thank-you message.
 * - Provides details about the next steps after order submission.
 * - Includes a button to navigate back to the home page.
 * - Utilizes the `useNavigate` hook from React Router for navigation.
 * - Accepts an optional `onReturnHome` prop to handle custom navigation behavior.
 * 
 * **Dependencies:**
 * - React and PropTypes.
 * - Material-UI components for styling: `Container`, `Typography`, `Paper`, `Button`, `Alert`.
 * - `useNavigate` from `react-router-dom`.
 * - Custom theme from [`src/theme.js`](src/theme.js).
 * 
 * **Usage:**
 * This component is typically rendered after the order form is successfully submitted in the [`OrderEquipmentForm`](src/components/equipment/EquipmentComponents.js) component.
 * 
 * **Example:**
 * ```jsx
 * import OrderSuccessPage from './OrderSuccessPage';
 * 
 * const SomeParentComponent = () => {
 *   return (
 *     <OrderSuccessPage onReturnHome={() => customNavigationFunction()} />
 *   );
 * };
 * ```
 */

import React from 'react';
import { Container, Typography, Paper, Button, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import theme from '../../theme';

/**
 * Component to display a success message after a successful order submission.
 *
 * @param {Function} onReturnHome - Callback function to navigate back to the home page.
 * @returns {JSX.Element}
 */
const OrderSuccessPage = ({ onReturnHome }) => {
  const navigate = useNavigate();

  const handleReturnHome = () => {
    if (onReturnHome) {
      onReturnHome();
    } else {
      navigate('/');
    }
  };

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          borderTop: 5,
          borderColor: theme.palette.primary.main,
        }}
      >
        <Alert severity="success" sx={{ mb: 2 }}>
          Tack för din beställning!
        </Alert>
        <Typography variant="body1" component="p">
          Din beställning har mottagits och kommer att behandlas. Du kommer att
          få ett bekräftelsemail när beställningen är registrerad.
        </Typography>
        <Button
          variant="contained"
          onClick={handleReturnHome}
          sx={{ mt: 2 }}
        >
          Tillbaka till startsidan
        </Button>
      </Paper>
    </Container>
  );
};

OrderSuccessPage.propTypes = {
  /**
   * Optional callback function to handle navigation back to the home page.
   * If not provided, the component will use `useNavigate` to navigate to '/'.
   */
  onReturnHome: PropTypes.func,
};

OrderSuccessPage.defaultProps = {
  onReturnHome: null,
};

export default OrderSuccessPage;
