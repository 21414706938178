/**
 * A component for managing phone-related subscriptions and models in an admin interface.
 * Provides functionality to view, add, edit, and delete phone call subscriptions, data subscriptions, and phone models.
 * 
 * @component
 * @returns {JSX.Element} A tabbed interface with three sections:
 *   - Licenser (Phone Call Subscriptions)
 *   - Mobildata (Phone Data Subscriptions)
 *   - Telefoner (Phone Models)
 * 
 * Each section contains:
 * - A button to add new items
 * - A data grid displaying existing items with edit and delete capabilities
 * - A dialog form for adding/editing items
 * 
 * @example
 * return (
 *   <PhoneManagementTab />
 * )
 * 
 * State management includes:
 * - activeSubTab: Controls which tab is currently active
 * - subscriptions: Stores call and data subscription data
 * - phoneModels: Stores phone model data
 * - dialogOpen: Controls the visibility of the add/edit dialog
 * - dialogType: Determines if the dialog is for adding or editing
 * - currentItem: Stores the currently selected item for editing
 * - loading: Indicates if data is being fetched
 * - error: Stores any error messages
 * - itemType: Identifies the type of item being managed (call/data/phone)
 */
// src/components/PhoneManagementTab.js

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  Alert,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  getPhoneCallSubscriptions,
  getPhoneDataSubscriptions,
  addPhoneCallSubscription,
  addPhoneDataSubscription,
  updatePhoneCallSubscription,
  updatePhoneDataSubscription,
  deletePhoneCallSubscription,
  deletePhoneDataSubscription,
  getPhoneModels,
  addPhoneModel,
  updatePhoneModel,
  deletePhoneModel,
} from '../../services/equipmentManagementService';

const PhoneManagementTab = () => {
  // State for tracking the active tab (0: Licenser, 1: Mobildata, 2: Telefoner)
  const [activeSubTab, setActiveSubTab] = useState(0);

  // State for storing phone call and data subscriptions, and phone models
  const [subscriptions, setSubscriptions] = useState({
    call: [],
    data: [],
  });
  const [phoneModels, setPhoneModels] = useState([]);

  // UI state for managing dialog visibility and form details
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(''); // 'add' or 'edit'
  const [currentItem, setCurrentItem] = useState(null); // Item being edited
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [itemType, setItemType] = useState(null); // 'call', 'data', or 'phone'

  // Function to fetch all data (phone call subscriptions, data subscriptions, and phone models)
  const fetchAllData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Fetch all data in parallel
      const [callSubs, dataSubs, phoneModelsData] = await Promise.all([
        getPhoneCallSubscriptions(),
        getPhoneDataSubscriptions(),
        getPhoneModels(),
      ]);

      // Update state with fetched data
      setSubscriptions({ call: callSubs, data: dataSubs });
      setPhoneModels(
        phoneModelsData.map((model) => ({
          ...model,
          itemType: 'phone',
        }))
      );
    } catch (err) {
      // Handle errors
      console.error('Error fetching data:', err);
      setError('Det gick inte att hämta data. Försök igen.');
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch data when the component mounts
  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  // Function to open the dialog for adding or editing an item
  const handleDialogOpen = (type, itemType, item = null) => {
    setDialogType(type);
    setItemType(itemType);
    setCurrentItem(item);
    setDialogOpen(true);
  };

  // Function to close the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
    setCurrentItem(null);
    setItemType(null);
  };

  // Function to handle form submission for adding or editing an item
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    // Collect form data
    const form = event.target;
    const formData = {
      name: form.Name.value,
      description: form.Description.value,
      price: form.Price.value,
    };

    try {
      // Handle add or edit operations based on dialogType
      if (dialogType === 'add') {
        if (itemType === 'call') await addPhoneCallSubscription(formData);
        else if (itemType === 'data') await addPhoneDataSubscription(formData);
        else if (itemType === 'phone') await addPhoneModel(formData);
      } else if (dialogType === 'edit') {
        if (itemType === 'call')
          await updatePhoneCallSubscription(currentItem.Id, formData);
        else if (itemType === 'data')
          await updatePhoneDataSubscription(currentItem.Id, formData);
        else if (itemType === 'phone')
          await updatePhoneModel(currentItem.Id, formData);
      } else {
        throw new Error('Unknown dialog type');
      }
      await fetchAllData(); // Refresh data after changes
      handleDialogClose(); // Close the dialog
    } catch (err) {
      console.error('Error submitting form:', err);
      setError(err.message || 'Det gick inte att spara. Försök igen.');
    }
  };

  // Function to handle deleting an item
  const handleDelete = async (type, id) => {
    setError(null);
    try {
      // Delete item based on type
      if (type === 'call') await deletePhoneCallSubscription(id);
      else if (type === 'data') await deletePhoneDataSubscription(id);
      else if (type === 'phone') await deletePhoneModel(id);
      else throw new Error('Invalid item type');
      await fetchAllData(); // Refresh data after deletion
    } catch (err) {
      console.error('Error deleting item:', err);
      setError('Det gick inte att radera. Försök igen.');
    }
  };

  // Columns definition for the DataGrid for subscriptions
  const subscriptionColumns = useMemo(
    () => [
      { field: 'Name', headerName: 'Namn', width: 200 },
      { field: 'Description', headerName: 'Beskrivning', width: 300 },
      { field: 'Price', headerName: 'Pris', width: 150 },
      {
        field: 'actions',
        headerName: 'Åtgärder',
        width: 200,
        renderCell: (params) => (
          <>
            <Button onClick={() => handleDialogOpen('edit', params.row.itemType, params.row)}>
              Redigera
            </Button>
            <Button onClick={() => handleDelete(params.row.itemType, params.row.Id)} color="error">
              Radera
            </Button>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  // Columns definition for the DataGrid for phone models
  const phoneColumns = useMemo(
    () => [
      { field: 'Name', headerName: 'Namn', width: 200 },
      { field: 'Description', headerName: 'Beskrivning', width: 300 },
      { field: 'Price', headerName: 'Pris', width: 150 },
      {
        field: 'actions',
        headerName: 'Åtgärder',
        width: 200,
        renderCell: (params) => (
          <>
            <Button onClick={() => handleDialogOpen('edit', 'phone', params.row)}>
              Redigera
            </Button>
            <Button onClick={() => handleDelete('phone', params.row.Id)} color="error">
              Radera
            </Button>
          </>
        ),
      },
    ],
    [handleDelete]
  );

  // Function to render the dialog for adding or editing items
  const renderDialog = () => (
    <Dialog open={dialogOpen} onClose={handleDialogClose}>
      <DialogTitle>
        {dialogType === 'add' ? 'Lägg till' : 'Redigera'}{' '}
        {itemType === 'call' ? 'licens' : itemType === 'data' ? 'mobildata' : 'telefon'}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="Name"
            label="Namn"
            fullWidth
            defaultValue={currentItem?.Name || ''}
            required
          />
          <TextField
            margin="dense"
            name="Description"
            label="Beskrivning"
            fullWidth
            defaultValue={currentItem?.Description || ''}
            required
          />
          <TextField
            margin="dense"
            name="Price"
            label="Pris"
            fullWidth
            defaultValue={currentItem?.Price || ''}
            required
            helperText={
              itemType === 'phone'
                ? "Ange pris som intervall, t.ex. '4800-5500kr'"
                : "Ange pris som t.ex. '79kr/mån'"
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Avbryt</Button>
          <Button type="submit">Spara</Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  // Render loading state
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  // Render error state
  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  // Helper function to render the DataGrid with a custom getRowId function
  const renderDataGrid = (rows, columns, getRowId) => (
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5, 10, 25]}
      autoHeight
      disableSelectionOnClick
      getRowId={getRowId}
    />
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Telefoni
      </Typography>

      {/* Tabs for switching between different phone management categories */}
      <Tabs
        value={activeSubTab}
        onChange={(e, newValue) => setActiveSubTab(newValue)}
        sx={{ mb: 3 }}
      >
        <Tab label="Licenser" />
        <Tab label="Mobildata" />
        <Tab label="Telefoner" />
      </Tabs>

      {/* Render content for the active tab */}
      {activeSubTab === 0 && (
        <Box>
          <Button onClick={() => handleDialogOpen('add', 'call')} sx={{ mb: 2 }}>
            Lägg till licens
          </Button>
          {renderDataGrid(
            subscriptions.call.map((sub) => ({ ...sub, itemType: 'call' })),
            subscriptionColumns,
            (row) => row.Id || row.ItemInternalId // Use Id or ItemInternalId as unique row identifier
          )}
        </Box>
      )}

      {activeSubTab === 1 && (
        <Box>
          <Button onClick={() => handleDialogOpen('add', 'data')} sx={{ mb: 2 }}>
            Lägg till mobildata
          </Button>
          {renderDataGrid(
            subscriptions.data.map((sub) => ({ ...sub, itemType: 'data' })),
            subscriptionColumns,
            (row) => row.Id || row.ItemInternalId // Use Id or ItemInternalId as unique row identifier
          )}
        </Box>
      )}

      {activeSubTab === 2 && (
        <Box>
          <Button onClick={() => handleDialogOpen('add', 'phone')} sx={{ mb: 2 }}>
            Lägg till telefon
          </Button>
          {renderDataGrid(
            phoneModels,
            phoneColumns,
            (row) => row.ItemInternalId || row.Id // Use ItemInternalId or Id as unique row identifier
          )}
        </Box>
      )}

      {renderDialog()}
    </Box>
  );
};

export default PhoneManagementTab;
