// src/services/graphApiService.js

/**
 * Graph API Service Module
 * 
 * This module provides an interface for interacting with the Microsoft Graph API,
 * specifically for user and group-related operations. It handles authentication,
 * request formatting, and error handling for Graph API calls.
 * 
 * **Core Functions:**
 * - `searchUsersGraphApi`: Searches for users by display name
 * - `getUserGroups`: Retrieves groups the authenticated user belongs to
 * - `getUsersByExtensionAttribute2`: Fetches users with specific extension attributes
 * 
 * **Features:**
 * - **Authentication**: Uses access tokens for secure API calls
 * - **Error Handling**: Comprehensive error catching and logging
 * - **Data Formatting**: Standardizes API responses into consistent formats
 * - **Query Parameters**: Supports OData filtering and selection
 * 
 * **API Endpoints Used:**
 * - `/users` - For user search and filtering
 * - `/me/memberOf` - For retrieving user's group memberships
 * - `/users?$filter=onPremisesExtensionAttributes` - For attribute-based user queries
 * 
 * **Usage:**
 * ```javascript
 * import { searchUsersGraphApi, getUserGroups, getUsersByExtensionAttribute2 } from './graphApiService';
 * 
 * // Search for users
 * const users = await searchUsersGraphApi('query', accessToken);
 * 
 * // Get user's groups
 * const groups = await getUserGroups(accessToken);
 * 
 * // Get users by extension attribute
 * const managers = await getUsersByExtensionAttribute2(accessToken, 'Attestant');
 * ```
 * 
 * **Error Handling:**
 * - All functions return empty arrays on error
 * - Errors are logged to console with detailed information
 * - Network errors and API errors are handled separately
 * 
 * **Dependencies:**
 * - Requires valid access token from MSAL authentication
 * - Uses native fetch API for HTTP requests
 * - Expects specific Microsoft Graph API endpoints and formats
 */

/**
 * Function to search for users in Microsoft Graph API.
 * @param {string} query - The search query string.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of user objects.
 */
const searchUsersGraphApi = async (query, accessToken) => {
  console.log('Searching users with query:', query);
  try {
    // Encode the query to handle special characters
    const encodedQuery = encodeURIComponent(query);

    // Construct the Graph API endpoint with OData query parameters
    const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${encodedQuery}')&$select=displayName,mail,id,jobTitle`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.value; // Return the array of users
    } else {
      console.error('Error fetching user suggestions from Microsoft Graph API:', response.status, response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error fetching user suggestions:', error);
    return [];
  }
};

/**
 * Fetch the groups that the authenticated user is a member of.
 * @param {string} accessToken - The access token for authentication.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of group objects.
 */
const getUserGroups = async (accessToken) => {
  try {
    const endpoint = `https://graph.microsoft.com/v1.0/me/memberOf?$select=displayName,id,securityEnabled`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.value; // Array of groups
    } else {
      console.error('Error fetching user groups:', response.status, response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error fetching user groups:', error);
    return [];
  }
};
/**
 * Fetch users with a specific extensionAttribute2 value from Microsoft Graph API.
 * @param {string} accessToken - The access token for authentication.
 * @param {string} attributeValue - The value to search for in extensionAttribute2.
 * @returns {Promise<Array>} - Returns a promise that resolves to an array of user objects.
 */
const getUsersByExtensionAttribute2 = async (accessToken, attributeValue) => {
  try {
    // Construct the Graph API endpoint with OData query parameters
    const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=onPremisesExtensionAttributes/extensionAttribute2 eq '${attributeValue}'&$select=displayName,mail,id,jobTitle,onPremisesExtensionAttributes&$count=true`;

    const response = await fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'ConsistencyLevel': 'eventual', // Add this header
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data.value.map(user => ({
        displayName: user.displayName,
        mail: user.mail,
        id: user.id,
        jobTitle: user.jobTitle,
        extensionAttribute2: user.onPremisesExtensionAttributes?.extensionAttribute2 || null,
      }));
    } else {
      const errorData = await response.json();
      console.error('Error fetching users from Microsoft Graph API:', response.status, response.statusText, errorData);
      return [];
    }
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};


export { searchUsersGraphApi, getUserGroups, getUsersByExtensionAttribute2 };