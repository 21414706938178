// src/components/ProtectedRoute.js

/**
 * ProtectedRoute Component
 * 
 * This component acts as a guard for protecting routes that require specific user authentication and authorization.
 * It ensures that only users who are authenticated and belong to a specific Azure Active Directory (AAD) security group
 * can access the wrapped routes.
 * 
 * Functionality:
 * - **Authentication Check**: Verifies if the user is logged in using MSAL React hooks.
 * - **Authorization Check**: Uses the Microsoft Graph API to fetch the user's group memberships and checks for the required group ID.
 * - **Access Control**:
 *   - Redirects unauthenticated users to the home page.
 *   - Redirects unauthorized users to an access denied page.
 *   - Allows authorized users to access the protected content.
 * - **Loading and Error Handling**:
 *   - Displays a loading indicator while performing checks.
 *   - Shows error messages if token acquisition or API calls fail.
 * 
 * Dependencies:
 * - React and React Hooks (`useEffect`, `useState`).
 * - React Router (`Navigate` for redirection).
 * - MSAL React (`useIsAuthenticated`, `useMsal` for authentication context).
 * - Microsoft Graph API service (`getUserGroups` for fetching group data).
 * - Material-UI components (`CircularProgress`, `Box`, `Alert`).
 * - Authentication configuration (`loginRequest` for token parameters).
 * 
 * Usage:
 * Wrap any route or component that requires protection with `ProtectedRoute` to enforce authentication and group-based authorization.
 * 
 * Example:
 * ```jsx
 * import ProtectedRoute from './components/ProtectedRoute';
 * import SensitivePage from './pages/SensitivePage';
 * 
 * function App() {
 *   return (
 *     <Routes>
 *       <Route
 *         path="/sensitive"
 *         element={
 *           <ProtectedRoute>
 *             <SensitivePage />
 *           </ProtectedRoute>
 *         }
 *       />
 *     </Routes>
 *   );
 * }
 * ```
 * 
 * In this example, the `/sensitive` route is protected, and only users who are authenticated and are members of the specified AAD security group
 * will be able to access the `SensitivePage` component.
 */


import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Paper, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Groups as GroupsIcon,
  PhoneAndroid as PhoneAndroidIcon,
  ArrowForward as ArrowForwardIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import theme from '../theme';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { getUserGroups } from '../services/graphApiService';


const OptionCard = ({ title, description, onClick, icon: Icon }) => (
  <Paper
    onClick={onClick}
    role="button"
    aria-label={title}
    tabIndex={0}
    onKeyDown={(e) => e.key === 'Enter' && onClick()}
    sx={{
      cursor: 'pointer',
      p: theme.spacing(3),
      mb: theme.spacing(2),
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
      borderRadius: '12px',
      transition: 'box-shadow 0.3s, transform 0.3s',
      '&:hover, &:focus': {
        transform: 'translateY(-3px)',
        boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.1)',
      },
      '&:focus': {
        outline: `${2}px solid ${theme.palette.primary.main}`,
        outlineOffset: '2px',
      },
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
      border: `1px solid ${theme.palette.divider}`,
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
      <Icon sx={{ fontSize: 40, color: theme.palette.primary.main, mr: 2 }} />
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
          {description}
        </Typography>
      </Box>
    </Box>
    <ArrowForwardIcon sx={{ color: theme.palette.primary.main, ml: 2 }} />
  </Paper>
);

OptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired,
};

const StartPage = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;
          const groups = await getUserGroups(accessToken);

          // Check if user is a member of the admin group
          const adminGroupId = '3661bf60-7b91-417a-bbb9-10c249903996'; // AAD-Orderforms-admin group ID
          setIsAdmin(groups.some(group => group.id === adminGroupId));
        } catch (error) {
          console.error('Error checking admin status:', error);
        }
      }
    };

    checkAdminStatus();
  }, [accounts, instance]);

  const orderOptions = [
    {
      id: 'create-team',
      title: 'Beställ ett Teams',
      description: 'Lägg en beställning för att skapa ett nytt Teams',
      icon: GroupsIcon,
    },
    {
      id: 'order-equipment',
      title: 'Beställ telefon och abonnemang',
      description: 'Beställ telefoner och abonnemang',
      icon: PhoneAndroidIcon,
    },
    // Add admin option if user is admin
    ...(isAdmin ? [{
      id: 'admin-dashboard',
      title: 'Admin Dashboard',
      description: 'Visa och hantera orderhistorik, behöriga beställare och telefoni',
      icon: DashboardIcon,
    }] : []),
  ];

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 } }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Beställningsformulär
          </Typography>
          <Typography variant="h6" color="textSecondary">
            Välj ett alternativ för att gå vidare
          </Typography>
        </Box>
        <Grid container spacing={3}>
          {orderOptions.map(({ id, title, description, icon }) => (
            <Grid item xs={12} key={id}>
              <OptionCard
                title={title}
                description={description}
                onClick={() => navigate(`/${id}`)}
                icon={icon}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default StartPage;