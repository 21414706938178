// src/components/common/ExportButton.js
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import theme from '../../theme';

// Function to handle exporting data to an Excel file
const exportToExcel = (data, filename) => {
  if (!data || data.length === 0) {
    alert('No data available to export.');
    return;
  }

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Export');
  XLSX.writeFile(workbook, filename);
};

/**
 * A button component that exports data to an Excel file when clicked.
 * @component
 * @param {Object} props - The component props
 * @param {Array|Object} props.data - The data to be exported to Excel
 * @param {string} props.filename - The name of the exported file
 * @param {string} props.label - The text label displayed on the button
 * @returns {JSX.Element} A Material-UI Button component with export functionality
 */
const ExportButton = ({ data, filename, label }) => (
  <Button
    variant="contained"
    color="primary"
    startIcon={<DownloadIcon />}
    onClick={() => exportToExcel(data, filename)}
    sx={{
      textTransform: 'none',
      bgcolor: theme.palette.primary.main,
      '&:hover': {
        bgcolor: theme.palette.primary.dark,
      },
    }}
  >
    {label}
  </Button>
);

ExportButton.propTypes = {
  data: PropTypes.array.isRequired,
  filename: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ExportButton;
