// src/components/TeamsComponents.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Box,
  Divider,
  List,
  InputAdornment,
  Chip,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Alert,
  Stack,
} from '@mui/material';
import theme from '../../theme';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import * as microsoftTeams from "@microsoft/teams-js";
import { isRunningInTeams } from '../../teamsSetup';  // Add this import
// Import TEAM_INFO from constants.js
import { TEAM_INFO } from './constants';

/* ======================= Custom Hooks ======================= */

/**
 * Custom hook for fetching users with debounce using Microsoft Graph API.
 */
const useUserSearch = () => {
  const { instance, accounts } = useMsal();
  const [suggestions, setSuggestions] = useState([]);

  const LOGIC_APP_URL = 'https://prod-13.swedencentral.logic.azure.com:443/workflows/7a5556081ab7469e8a8bc360c2fb2a9f/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=g9RwK1e1_Dz9oOU9eQSYjidgyhPvmAfBkz9mO11Ah0E';

  const fetchUsers = useCallback(
    debounce(async (value) => {
      if (value.length > 1) {
        try {
          let users;
          
          if (isRunningInTeams()) {
            // Use Logic App when running in Teams
            const response = await fetch(LOGIC_APP_URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                searchQuery: value
              })
            });

            if (!response.ok) {
              throw new Error('Failed to fetch users from Logic App');
            }

            const data = await response.json();
            users = data.value;
          } else {
            // Use direct Graph API call for web app
            const response = await instance.acquireTokenSilent({
              ...loginRequest,
              account: accounts[0],
            });
            const accessToken = response.accessToken;

            const encodedQuery = encodeURIComponent(value);
            const endpoint = `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${encodedQuery}')&$select=displayName,mail,id,jobTitle`;

            const graphResponse = await fetch(endpoint, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            });

            if (!graphResponse.ok) {
              throw new Error('Failed to fetch users from Graph API');
            }

            const data = await graphResponse.json();
            users = data.value;
          }

          setSuggestions(users);
        } catch (error) {
          console.error('Error fetching users:', error);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    [instance, accounts]
  );

  useEffect(() => {
    return () => {
      fetchUsers.cancel();
    };
  }, [fetchUsers]);

  return { suggestions, fetchUsers, setSuggestions };
};

/* ======================= Components ======================= */

/**
 * SuggestionItem Component
 * Renders individual user suggestions.
 */
const SuggestionItem = ({ user, onSelect }) => (
  <ListItem button onClick={() => onSelect(user)}>
    <ListItemAvatar>
      <Avatar>{user.displayName.charAt(0)}</Avatar>
    </ListItemAvatar>
    <ListItemText primary={user.displayName} secondary={user.mail} />
  </ListItem>
);

SuggestionItem.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    mail: PropTypes.string,
    id: PropTypes.string.isRequired,
    jobTitle: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

/**
 * TextFieldField Component
 */
const TextFieldField = ({
  label,
  focusedLabel,
  id,
  placeholder,
  helperText,
  type = 'text',
  value,
  onChange,
  error,
  errorMessage,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextField
      label={isFocused ? focusedLabel : label}
      fullWidth
      id={id}
      variant="outlined"
      margin="normal"
      placeholder={placeholder}
      helperText={error ? errorMessage : helperText}
      type={type}
      value={value}
      onChange={onChange}
      error={error}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        '& .MuiInputBase-input::placeholder': {
          opacity: 0.7,
        },
        '& .MuiInputBase-input:focus::placeholder': {
          opacity: 0,
        },
      }}
    />
  );
};

TextFieldField.propTypes = {
  label: PropTypes.string.isRequired,
  focusedLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

/**
 * UserInput Component
 */
const UserInput = ({
  defaultLabel,
  focusedLabel,
  id,
  placeholder,
  helperText,
  selectedUser,
  onUserSelect,
  error,
  errorMessage,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const { suggestions, fetchUsers, setSuggestions } = useUserSearch();

  const handleInput = (e) => {
    const value = e.target.value;
    setInputValue(value);
    fetchUsers(value);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target) &&
        event.target !== inputRef.current
      ) {
        setSuggestions([]);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [setSuggestions]);

  const handleUserSelect = (user) => {
    onUserSelect(user);
    setInputValue('');
    setSuggestions([]);
  };

  const removeSelectedUser = () => {
    onUserSelect(null);
  };

  return (
    <Box sx={{ marginBottom: 2, position: 'relative' }}>
      <TextField
        label={focused ? focusedLabel : defaultLabel}
        fullWidth
        id={id}
        inputRef={inputRef}
        value={inputValue}
        onChange={handleInput}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        margin="normal"
        variant="outlined"
        placeholder={placeholder}
        helperText={helperText}
        error={error}
        FormHelperTextProps={{ error: error }}
        InputProps={{
          startAdornment: selectedUser && (
            <InputAdornment position="start">
              <Chip
                avatar={<Avatar>{selectedUser.displayName.charAt(0)}</Avatar>}
                label={selectedUser.displayName}
                onDelete={removeSelectedUser}
                sx={{ marginRight: 1 }}
              />
            </InputAdornment>
          ),
        }}
        disabled={!!selectedUser}
      />
      {error && errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
      {suggestions.length > 0 && (
        <List
          ref={suggestionsRef}
          sx={{
            position: 'absolute',
            zIndex: 10,
            bgcolor: 'background.paper',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            width: '100%',
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {suggestions.map((user) => (
            <SuggestionItem key={user.id} user={user} onSelect={handleUserSelect} />
          ))}
        </List>
      )}
    </Box>
  );
};

UserInput.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  focusedLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  selectedUser: PropTypes.object,
  onUserSelect: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

/**
 * TeamForm Component
 */
const TeamForm = () => {
  const { teamType } = useParams();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const teamInfo = TEAM_INFO[teamType] || {};

  // Define labels based on teamType
  const teamOwnerDefaultLabel =
    teamType === 'projektteam'
      ? 'Ange teamsägare (projekt-/uppdragsledare)'
      : teamType === 'samverkansteam'
      ? 'Ange teamsägare (samverkansansvarig)'
      : teamType === 'organisationsteam'
      ? 'Ange teamsägare (enhetschef)'
      : 'Ange teamsägare';

  const secondaryOwnerDefaultLabel =
    teamType === 'projektteam'
      ? 'Ange en sekundär teamsägare (nästansvarig för projekt/uppdrag)'
      : teamType === 'samverkansteam'
      ? 'Ange en sekundär teamsägare (nästansvarig för samverkan)'
      : teamType === 'organisationsteam'
      ? 'Ange en sekundär teamsägare (biträdande EC/samordnare)'
      : 'Ange en sekundär teamsägare';

  const initialValues = {
    teamOwner: null,
    secondaryOwner: null,
    requester: null,
    samverkansnamn: '',
    enhetensNamn: '',
    projektUppdragsNamn: '',
    archiveDate: '',
  };

  const validationSchema = Yup.object().shape({
    teamOwner: Yup.object().nullable().required('Ange en teamsägare.'),
    secondaryOwner: Yup.object()
      .nullable()
      .required('Ange en sekundär teamsägare.')
      .test(
        'not-same-as-owner',
        'Sekundär teamsägare kan inte vara samma person som primär teamsägare.',
        function(value, context) {
          const teamOwner = context.parent.teamOwner;
          if (!value || !teamOwner) return true;
          return value.id !== teamOwner.id;
        }
      ),
    requester: Yup.object().nullable().required('Ange en beställare.'),
    samverkansnamn:
      teamType === 'samverkansteam'
        ? Yup.string().trim().required('Ange samverkansnamn.')
        : Yup.string(),
    enhetensNamn:
      teamType === 'organisationsteam'
        ? Yup.string().trim().required('Ange enhetens namn.')
        : Yup.string(),
    projektUppdragsNamn:
      teamType === 'projektteam'
        ? Yup.string().trim().required('Ange projekt/uppdragsnamn.')
        : Yup.string(),
    archiveDate:
      teamType === 'projektteam'
        ? Yup.date().required('Ange arkiveringsdatum.')
        : Yup.date(),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const logicAppUrl =
      'https://prod-23.swedencentral.logic.azure.com:443/workflows/7cb41845bac5429fb87acfb86d1021d2/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=bbJF09T66AgFjU0H3643DQZCFsWPmIYHcYU5qJujPuc';

    // Modify form data based on team type
    const formData = {
      teamOwner: values.teamOwner.mail,
      secondaryOwner: values.secondaryOwner.mail,
      requester: values.requester.mail,
      teamType,
    };

    if (teamType === 'organisationsteam') {
      formData.teamName = values.enhetensNamn;
    } else if (teamType === 'samverkansteam') {
      formData.teamName = values.samverkansnamn;
    } else if (teamType === 'projektteam') {
      formData.teamName = values.projektUppdragsNamn;
      formData.archiveDate = values.archiveDate;
    }

    try {
      const response = await fetch(logicAppUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        console.error('Error submitting form data to Logic App');
      }
    } catch (error) {
      console.error('Error submitting form data to Logic App', error);
    }

    setSubmitting(false);
  };

  if (isSubmitted) {
    return (
      <Container maxWidth="md" sx={{ my: 4 }}>
        <Paper
          elevation={3}
          sx={{ p: 4, borderTop: 5, borderColor: theme.palette.primary.main }}
        >
          <Alert severity="success" sx={{ mb: 2 }}>
            Tack för din beställning!
          </Alert>
          <Typography variant="body1" paragraph>
            Samverkansteams och projektteams skapas automatiskt. Organisationsteams skapas manuellt och du
            blir kontaktad av handläggare vid frågor eller när teamet är skapat.
          </Typography>
          <Button variant="contained" onClick={() => navigate('/')} sx={{ mt: 2 }}>
            Tillbaka till startsidan
          </Button>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper
        elevation={3}
        sx={{ p: 4, borderTop: 5, borderColor: theme.palette.primary.main }}
      >
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }}>
          {teamInfo.title}
        </Typography>
        <Typography variant="body1" paragraph color="text.secondary">
          {teamInfo.infoText}
        </Typography>
        <Divider sx={{ my: 3 }} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting }) => (
            <Form>
              <Stack spacing={2}>
                <UserInput
                  defaultLabel={teamOwnerDefaultLabel}
                  focusedLabel="Teamsägare"
                  id={`${teamType}Owner`}
                  placeholder="Sök användare"
                  helperText="Teamsägaren har fullständiga administratörsrättigheter."
                  selectedUser={values.teamOwner}
                  onUserSelect={(user) => setFieldValue('teamOwner', user)}
                  error={Boolean(touched.teamOwner && errors.teamOwner)}
                  errorMessage={errors.teamOwner}
                />
                <UserInput
                  defaultLabel={secondaryOwnerDefaultLabel}
                  focusedLabel="Sekundär teamsägare"
                  id={`${teamType}SecondaryOwner`}
                  placeholder="Sök användare"
                  helperText="Sekundär ägare har samma rättigheter som primär."
                  selectedUser={values.secondaryOwner}
                  onUserSelect={(user) => setFieldValue('secondaryOwner', user)}
                  error={Boolean(touched.secondaryOwner && errors.secondaryOwner)}
                  errorMessage={errors.secondaryOwner}
                />

                {/* Conditional Rendering of Name Fields Based on Team Type */}
                {teamType === 'samverkansteam' && (
                  <TextFieldField
                    focusedLabel="Samverkansnamn"
                    id={`${teamType}Samverkansnamn`}
                    placeholder="Ange samverkansnamn"
                    helperText="Beskriv teamets syfte eller funktion"
                    value={values.samverkansnamn}
                    onChange={(e) => setFieldValue('samverkansnamn', e.target.value)}
                    error={Boolean(touched.samverkansnamn && errors.samverkansnamn)}
                    errorMessage={errors.samverkansnamn}
                  />
                )}

                {teamType === 'organisationsteam' && (
                  <TextFieldField
                    focusedLabel="Enhetens namn"
                    id={`${teamType}EnhetensNamn`}
                    placeholder="Ange enhetens namn"
                    helperText="Ange enhetens namn"
                    value={values.enhetensNamn}
                    onChange={(e) => setFieldValue('enhetensNamn', e.target.value)}
                    error={Boolean(touched.enhetensNamn && errors.enhetensNamn)}
                    errorMessage={errors.enhetensNamn}
                  />
                )}

              {teamType === 'projektteam' && (
                <>
                  <TextFieldField
                    focusedLabel="Projekt/Uppdragsnamn"
                    id={`${teamType}ProjektUppdragsNamn`}
                    placeholder="Ange projekt/uppdragsnamn"
                    helperText="Beskriv teamets syfte eller funktion"
                    value={values.projektUppdragsNamn}
                    onChange={(e) => setFieldValue('projektUppdragsNamn', e.target.value)}
                    error={Boolean(touched.projektUppdragsNamn && errors.projektUppdragsNamn)}
                    errorMessage={errors.projektUppdragsNamn}
                  />
                  <TextFieldField
                    label="Arkiveringsdatum"
                    focusedLabel="Arkiveringsdatum"
                    id="archiveDate"
                    type="date"
                    helperText="Ange ett datum för arkivering av teamsgruppen."
                    value={values.archiveDate}
                    onChange={(e) => setFieldValue('archiveDate', e.target.value)}
                    error={Boolean(touched.archiveDate && errors.archiveDate)}
                    errorMessage={errors.archiveDate}
                  />
                  
                  {/* Orange warning box */}
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      bgcolor: 'warning.light',
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'warning.main',
                    }}
                  >
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                      <strong>Viktigt om arkivering:</strong>
                    </Typography>
                    <ul style={{ marginTop: 0, marginBottom: 0, paddingLeft: '1.2em' }}>
                      <li>
                        <Typography variant="body2" color="text.secondary">
                          Arkivering av teamet bör ske max 1 månad efter projektet/uppdragsavslut.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2" color="text.secondary">
                          Projekt/uppdragsdokumentation flyttas till projektarkivering vid behov inför arkivering av teamsgruppen.
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2" color="text.secondary">
                          Mer information är tillgänglig vid skapande av teamsgruppen.
                        </Typography>
                      </li>
                    </ul>
                  </Box>
                </>
              )}
                <UserInput
                  defaultLabel="Ange beställare"
                  focusedLabel="Beställare"
                  id={`${teamType}Requester`}
                  placeholder="Sök användare"
                  helperText="Beställaren ansvarar för teamsbeställningen."
                  selectedUser={values.requester}
                  onUserSelect={(user) => setFieldValue('requester', user)}
                  error={Boolean(touched.requester && errors.requester)}
                  errorMessage={errors.requester}
                />
              </Stack>
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  sx={{
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.text.primary,
                  }}
                >
                  Tillbaka
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    '&:hover': { bgcolor: theme.palette.primary.dark },
                  }}
                >
                  {isSubmitting ? 'Beställer...' : 'Beställ'}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

/* ======================= Export Components ======================= */

export { TeamForm };
