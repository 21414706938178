// src/hooks/useOrders.js
/**
 * useOrders Hook
 * 
 * A custom React hook that manages order history data fetching and filtering.
 * It provides state management for orders, including loading states and error handling.
 * 
 * **Features:**
 * - **Data Fetching**: Retrieves order history data using the orderService.
 * - **State Management**: Manages both original and filtered order lists.
 * - **Loading State**: Tracks the loading state during data fetching.
 * - **Error Handling**: Captures and exposes error states during fetch operations.
 * - **Filtering Support**: Maintains separate states for original and filtered orders.
 * 
 * **Usage:**
 * ```jsx
 * const { 
 *   orders,           // Original orders array
 *   filteredOrders,   // Filtered orders array
 *   setFilteredOrders,// Function to update filtered orders
 *   loading,          // Loading state
 *   error,           // Error state
 *   fetchOrders      // Function to manually refetch
 * } = useOrders();
 * ```
 * 
 * **Dependencies:**
 * - React hooks: useState, useEffect
 * - getOrderHistory from orderService
 */

import { useState, useEffect } from 'react';
import { getOrderHistory } from '../services/orderService';

const useOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchOrders = async () => {
    setLoading(true);
    setError(false);
    try {
      const data = await getOrderHistory();
      setOrders(data);
      setFilteredOrders(data);
    } catch (err) {
      console.error('Error fetching order history:', err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return { orders, filteredOrders, setFilteredOrders, loading, error, fetchOrders };
};

export default useOrders;
