// src/components/OrderHistoryTab.js

import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Alert,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DataGridStyled from '../common/DataGridStyled';
import ExportButton from '../common/ExportButton';
import useOrders from '../../hooks/useOrders';

/**
 * OrderHistoryTab component displays a table of order history with filtering and export functionalities.
 *
 * Utilizes the `useOrders` hook to fetch and manage orders data. Provides users with the ability to:
 * - Search orders based on a query.
 * - Filter orders by a specified date range using start and end dates.
 * - Export the filtered orders to an Excel file.
 *
 * The component handles loading and error states, displaying appropriate indicators or messages.
 * It leverages Material-UI components for layout and styling, including `Box`, `Typography`, `TextField`, `DatePicker`, and `DataGridStyled`.
 *
 * @component
 * @returns {JSX.Element} The rendered OrderHistoryTab component.
 */
const OrderHistoryTab = () => {
  // Destructure values from the custom `useOrders` hook
  const { orders, filteredOrders, setFilteredOrders, loading, error } = useOrders();

  // State for search query and date filters
  const [searchQuery, setSearchQuery] = useState('');
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  // Define columns for the DataGrid using `useMemo` to optimize performance
  const columns = useMemo(
    () => [
      { field: 'TeamName', headerName: 'Namn', width: 300 }, // Column for team name
      { field: 'RequestedByUser', headerName: 'Beställare', width: 300 }, // Column for requester
      { field: 'CreatedAt', headerName: 'Datum', width: 150 }, // Column for creation date
      { field: 'TeamType', headerName: 'Teamtyp', width: 150 }, // Column for team type
      { field: 'ArchiveDate', headerName: 'Arkiveringsdatum', width: 200 }, // Column for archive date
    ],
    []
  );

  // Apply filters whenever the search query, start date, or end date changes
  useEffect(() => {
    const applyFilters = () => {
      // Start with all orders
      let filtered = [...orders];

      // Filter orders based on the search query
      if (searchQuery) {
        const query = searchQuery.toLowerCase();
        filtered = filtered.filter(order =>
          Object.values(order).some(
            value => value?.toString().toLowerCase().includes(query)
          )
        );
      }

      // Filter orders based on the start date
      if (startDateFilter) {
        filtered = filtered.filter(
          order => new Date(order.CreatedAt) >= startDateFilter.toDate()
        );
      }

      // Filter orders based on the end date
      if (endDateFilter) {
        filtered = filtered.filter(
          order => new Date(order.CreatedAt) <= endDateFilter.toDate()
        );
      }

      // Update the filtered orders in the state
      setFilteredOrders(filtered);
    };

    // Call the filtering function
    applyFilters();
  }, [searchQuery, startDateFilter, endDateFilter, orders, setFilteredOrders]);

  return (
    <>
      {/* Header Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Orderhistorik
        </Typography>
      </Box>

      {/* Export Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <ExportButton
          data={filteredOrders}
          filename="OrdersHistory.xlsx"
          label="Exportera till Excel"
        />
      </Box>

      {/* Filters Section */}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mb: 2,
          justifyContent: 'space-between',
        }}
      >
        {/* Search Input */}
        <TextField
          label="Sök"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ minWidth: 200 }}
        />

        {/* Start Date Picker */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Startdatum"
            value={startDateFilter}
            onChange={(newValue) => setStartDateFilter(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        {/* End Date Picker */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Slutdatum"
            value={endDateFilter}
            onChange={(newValue) => setEndDateFilter(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      {/* Conditional Rendering for Loading, Error, and Data Grid */}
      {loading ? (
        // Show a loading spinner when data is being fetched
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        // Show an error message if there's an error
        <Alert severity="error" sx={{ mb: 2 }}>
          Misslyckades att ladda orderhistorik.
        </Alert>
      ) : (
        // Show the DataGrid when data is loaded
        <div style={{ height: 600, width: '100%' }}>
          <DataGridStyled
            rows={filteredOrders}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row.TeamId} // Use TeamId as the unique row identifier
            rowsPerPageOptions={[10, 25, 50]}
            pagination
            disableSelectionOnClick
            sortingOrder={['asc', 'desc']} // Default sorting order
            sortModel={[]} // Initial sort model
          />
        </div>
      )}
    </>
  );
};

export default OrderHistoryTab;
