// src/components/ProtectedRoute.js
/**
 * ProtectedRoute Component
 * 
 * This component acts as a route guard to protect certain routes within the application,
 * ensuring that only authenticated users who are members of a specific Azure Active Directory (AAD)
 * security group can access them.
 * 
 * **Functionality:**
 * - **Authentication Verification**: Confirms that the user is authenticated using MSAL React hooks.
 * - **Authorization Verification**: Fetches the user's group memberships from Microsoft Graph API to
 *   check if they belong to the required security group specified by `REQUIRED_GROUP_ID`.
 * - **Access Control**:
 *   - Redirects unauthenticated users to the home page.
 *   - Redirects authenticated but unauthorized users to an access denied page.
 *   - Allows authenticated and authorized users to access the protected route.
 * - **Loading and Error Handling**:
 *   - Displays a loading spinner while verifying authentication and authorization.
 *   - Shows an error message if there's a failure during token acquisition or group fetching.
 * 
 * **Dependencies:**
 * - **React and Hooks**: For component structure and state management.
 * - **React Router**: For navigation and redirection (`Navigate`).
 * - **MSAL React**: For authentication status (`useIsAuthenticated`) and MSAL instance access (`useMsal`).
 * - **Microsoft Graph API**: To fetch user group memberships (`getUserGroups`).
 * - **Material-UI Components**: `CircularProgress`, `Box`, `Alert` for UI feedback.
 * - **Authentication Config**: Authentication parameters from `authConfig.js`.
 * 
 * **Usage:**
 * Wrap any route that needs protection with the `ProtectedRoute` component to enforce authentication
 * and group-based authorization.
 * 
 * **Example:**
 * ```jsx
 * import ProtectedRoute from './components/ProtectedRoute';
 * import AdminPage from './pages/AdminPage';
 * 
 * function AppRoutes() {
 *   return (
 *     <Routes>
 *       <Route
 *         path="/admin"
 *         element={
 *           <ProtectedRoute>
 *             <AdminPage />
 *           </ProtectedRoute>
 *         }
 *       />
 *     </Routes>
 *   );
 * }
 * ```
 * 
 * In the example above, the `/admin` route is protected, and only users who are
 * authenticated and authorized (members of the specified AAD group) can access the `AdminPage` component.
 */

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getUserGroups } from '../services/graphApiService';
import { loginRequest } from '../authConfig';
import { CircularProgress, Box, Alert } from '@mui/material';

// Use the group ID for the "AAD-Orderforms-admin" group
const REQUIRED_GROUP_ID = '3661bf60-7b91-417a-bbb9-10c249903996';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkGroupMembership = async () => {
      if (isAuthenticated && accounts.length > 0) {
        try {
          // Acquire an access token for Microsoft Graph
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          const accessToken = response.accessToken;

          // Fetch user groups
          const groups = await getUserGroups(accessToken);

          // Check if the user is a member of the required group by its ID
          const isMember = groups.some(
            (group) => group.id === REQUIRED_GROUP_ID && group.securityEnabled
          );

          setIsAuthorized(isMember);
        } catch (err) {
          console.error('Error acquiring token or fetching groups:', err);
          setError('Failed to verify group membership.');
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    checkGroupMembership();
  }, [isAuthenticated, accounts, instance]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (!isAuthorized) {
    return <Navigate to="/access-denied" replace />;
  }

  return children;
};

export default ProtectedRoute;
