/**
 * useCurrentUser Hook
 * 
 * This custom React hook retrieves the currently authenticated user using the Microsoft Authentication Library (MSAL).
 * It listens for changes in the authentication state and updates the user information accordingly.
 * 
 * **Features:**
 * - **Authentication State Monitoring**: Utilizes the `useMsal` hook to access MSAL authentication state and accounts.
 * - **User Retrieval**: Determines the current user based on the accounts available in MSAL, assuming the first account is active.
 * - **State Management**: Maintains the authenticated user's information using React's `useState` and updates it with `useEffect`.
 * 
 * **Usage:**
 * Import and invoke the `useCurrentUser` hook within your functional components to access the authenticated user's details.
 * 
 * **Example:**
 * ```jsx
 * import React from 'react';
 * import useCurrentUser from '../hooks/useCurrentUser';
 * 
 * const Dashboard = () => {
 *   const currentUser = useCurrentUser();
 * 
 *   return (
 *     <div>
 *       {currentUser ? (
 *         <h1>Welcome, {currentUser.name}</h1>
 *       ) : (
 *         <h1>Please sign in to continue.</h1>
 *       )}
 *     </div>
 *   );
 * };
 * 
 * export default Dashboard;
 * ```
 * 
 * **Dependencies:**
 * - React hooks: `useEffect`, `useState`
 * - MSAL React hook: `useMsal` from `@azure/msal-react`
 * 
 * **Notes:**
 * - Assumes that the first account in the `accounts` array is the currently active user.
 * - Returns `null` when no user is authenticated.
 */
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';

/**
 * Custom hook to retrieve the current authenticated user.
 *
 * @returns {Object|null} The current user object or null if not authenticated.
 */
const useCurrentUser = () => {
  const { accounts } = useMsal();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (accounts.length > 0) {
      // Assuming the first account is the active one
      setCurrentUser(accounts[0]);
    } else {
      setCurrentUser(null);
    }
  }, [accounts]);

  return currentUser;
};

export default useCurrentUser;
