/**
 * EquipmentComponents Module
 * 
 * This module provides components for rendering an equipment order form within the application.
 * It allows users to request telephony equipment, including mobile subscriptions, data plans, and phones.
 * 
 * **Components:**
 * - `OrderEquipment`: Main component that displays the equipment order form and handles form submission.
 * - `OrderEquipmentForm`: Contains the form logic, including state management, validation, and submission handling.
 * - `FormTextField`: Custom component for rendering text input fields integrated with Formik.
 * - `FormRadioGroup`: Custom component for rendering radio button groups for selecting equipment options.
 * 
 * **Features:**
 * - **Data Fetching**: Retrieves available options for mobile subscriptions, data subscriptions, and phone models from backend services.
 * - **Form Validation**: Utilizes Yup for schema validation to ensure all required fields are correctly filled out.
 * - **Form Submission**: Submits the collected form data to a backend service for processing the equipment order.
 * - **User Authentication**: Obtains the current user's information using the `useCurrentUser` hook to include in the submission data.
 * - **Loading and Error Handling**: Manages loading states and displays appropriate error messages if data fetching or submission fails.
 * 
 * **Dependencies:**
 * - React, PropTypes, Formik, Yup
 * - Material-UI components for UI styling and layout
 * - Custom services and hooks: `useCurrentUser`, `submitForm`
 * - Equipment management services from [`src/services/equipmentManagementService.js`](src/services/equipmentManagementService.js)
 * 
 * **Usage:**
 * Import the `OrderEquipment` component and include it in the desired route or component to render the equipment order form.
 * 
 * **Example:**
 * ```jsx
 * import { OrderEquipment } from './EquipmentComponents';
 * 
 * function App() {
 *   return (
 *     <OrderEquipment />
 *   );
 * }
 * ```
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Paper, Box, Divider, Grid, Button, TextField,
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import theme from '../../theme';
import submitForm from '../../formSubmission';
import {
  getPhoneCallSubscriptions,
  getPhoneDataSubscriptions,
  getPhoneModels,
} from '../../services/equipmentManagementService';
import useCurrentUser from '../../hooks/useCurrentUser';
import OrderSuccessPage from './OrderSuccessPage'; // Imported OrderSuccessPage component


const validationSchema = Yup.object({
  employeeName: Yup.string().required('Employee name is required'),
  costCenter: Yup.string().required('Cost center is required'),
  deliveryAddress: Yup.string().required('Delivery address is required'),
  deliveryCity: Yup.string().required('City is required'),
  postalCode: Yup.string().matches(/^\d{3}\s?\d{2}$/, 'Must be a valid 5-digit postal code').required('Postal code is required'),
  contactPerson: Yup.string().required('Contact person is required'),
  contactPhone: Yup.string().matches(/^\d{10}$/, 'Must be a valid Swedish phone number').required('Contact phone is required'),
  mobileSubscription: Yup.string().required('Mobile subscription is required'),
  mobileData: Yup.string().required('Mobile data is required'),
  phone: Yup.string().required('Phone is required'),
});

const initialValues = {
  employeeName: '',
  costCenter: '',
  deliveryAddress: '',
  deliveryCity: '',
  postalCode: '',
  contactPerson: '',
  contactPhone: '',
  mobileSubscription: '',
  mobileData: '',
  phone: '',
};

const formFields = [
  { 
    name: 'employeeName', 
    label: 'Medarbetarens namn', 
    placeholder: 'Ange medarbetarens namn', 
    helperText: 'Ange för- och efternamn på medarbetaren',
    gridWidth: 6 
  },
  { 
    name: 'costCenter', 
    label: 'Kostnadsställe', 
    placeholder: 'Ange kostnadsställe', 
    helperText: 'Ange kostnadsställe för utrustningen',
    gridWidth: 6 
  },
  { 
    name: 'deliveryAddress', 
    label: 'Leverans - Adress', 
    placeholder: 'Ange leveransadress', 
    helperText: 'Ange gatuadress för leverans',
    gridWidth: 12 
  },
  { 
    name: 'deliveryCity', 
    label: 'Leverans - Ort', 
    placeholder: 'Ange ort', 
    helperText: 'Ange ort för leverans',
    gridWidth: 6 
  },
  { 
    name: 'postalCode', 
    label: 'Leverans - Postnummer', 
    placeholder: 'Ange postnummer', 
    helperText: 'Ange ett giltigt postnummer',
    gridWidth: 6 
  },
  { 
    name: 'contactPerson', 
    label: 'Leverans - Kontaktperson', 
    placeholder: 'Ange kontaktperson', 
    helperText: 'Ange namn på kontaktpersonen för leveransen',
    gridWidth: 6 
  },
  { 
    name: 'contactPhone', 
    label: 'Leverans - Telefonnummer till kontaktperson', 
    placeholder: 'Ange telefonnummer', 
    helperText: 'Ange telefonnummer till kontaktpersonen',
    gridWidth: 6 
  },
];

const FormTextField = ({ name, label, formik, required, placeholder, helperText }) => (
  <TextField
    fullWidth
    id={name}
    name={name}
    label={label}
    value={formik.values[name]}
    onChange={formik.handleChange}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name] ? formik.errors[name] : helperText}
    required={required}
    placeholder={placeholder}
    sx={{ marginBottom: 2 }}
  />
);

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

const FormRadioGroup = ({ name, label, options, formik, color = 'primary' }) => (
  <FormControl component="fieldset" fullWidth>
    <FormLabel 
      component="legend" 
      sx={{ 
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '1.1rem',
        mb: 2
      }}
    >
      {label}
    </FormLabel>
    <RadioGroup 
      name={name} 
      value={formik.values[name]} 
      onChange={formik.handleChange}
      sx={{ gap: 1 }}
    >
      {options.map((option) => (
        <Box key={option.value}>
          <FormControlLabel 
            value={option.value} 
            control={<Radio color={color} />} 
            label={
              <Box>
                <Typography 
                  component="div" 
                  sx={{ 
                    fontWeight: 500,
                    display: 'flex',
                    gap: 1
                  }}
                >
                  <span>{option.label}</span>
                  {option.price && (
                    <span style={{ color: theme.palette.text.secondary }}>
                      ({option.price})
                    </span>
                  )}
                </Typography>
                {option.description && (
                  <Typography
                    component="div"
                    sx={{ 
                      ml: 0,
                      color: theme.palette.text.secondary,
                      fontSize: '0.875rem',
                      mt: 0.5
                    }}
                  >
                    {option.description}
                  </Typography>
                )}
              </Box>
            }
            sx={{
              alignItems: 'flex-start',
              '.MuiRadio-root': {
                pt: 0
              },
              mb: 1
            }}
          />
        </Box>
      ))}
    </RadioGroup>
  </FormControl>
);

FormRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      price: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  formik: PropTypes.object.isRequired,
  color: PropTypes.string,
};

const OrderEquipment = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  if (isSubmitted) {
    return <OrderSuccessPage onReturnHome={() => navigate('/')} />;
  }

  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderTop: 5, borderColor: theme.palette.primary.main }}>
        <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main }} align="center">
          Telefoni
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <OrderEquipmentForm onSubmitSuccess={() => setIsSubmitted(true)} />
      </Paper>
    </Container>
  );
};

const OrderEquipmentForm = ({ onSubmitSuccess }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  
  const currentUser = useCurrentUser();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [callSubs, dataSubs, phones] = await Promise.all([
          getPhoneCallSubscriptions(),
          getPhoneDataSubscriptions(),
          getPhoneModels(),
        ]);

        const noneOptions = {
          subscription: { 
            value: 'none', 
            label: 'Inget mobilabonnemang', 
            price: null,
            description: null 
          },
          data: { 
            value: 'none', 
            label: 'Ingen mobildata', 
            price: null,
            description: null 
          },
          phone: { 
            value: 'none', 
            label: 'Ingen telefon', 
            price: null,
            description: null 
          },
        };

        setSubscriptionOptions([
          ...callSubs.map(sub => ({
            value: sub.Name,
            label: sub.Name,
            price: sub.Price,
            description: sub.Description
          })),
          noneOptions.subscription
        ]);

        setDataOptions([
          ...dataSubs.map(sub => ({
            value: sub.Name,
            label: sub.Name,
            price: sub.Price,
            description: sub.Description
          })),
          noneOptions.data
        ]);

        setPhoneOptions([
          ...phones.map(phone => ({
            value: phone.Name,
            label: phone.Name,
            price: phone.Price,
            description: phone.Description
          })),
          noneOptions.phone
        ]);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching options:', err);
        setError('Failed to load options. Please try again later.');
        setLoading(false);
      }
    };

    fetchOptions();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (!currentUser) {
        setError('Unable to identify the current user. Please try again.');
        return;
      }

      setSubmissionStatus('submitting');
      
      const submissionData = {
        ...values,
        submitter: currentUser.username,
        submissionDate: new Date().toISOString()
      };

      const logicAppUrl = 'https://prod-01.swedencentral.logic.azure.com:443/workflows/cb04d83779cd422bb96b0efa5d7e228f/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=mYOTA0jvxbMO_db11CM3BHGzIu6rE8juh2vuGc3gdfo';
      
      try {
        const success = await submitForm(submissionData, logicAppUrl);
        if (success) {
          setSubmissionStatus('success');
          formik.resetForm();
          onSubmitSuccess();
        } else {
          setSubmissionStatus('error');
          setError('Failed to submit the order. Please try again.');
        }
      } catch (err) {
        console.error('Error submitting form:', err);
        setSubmissionStatus('error');
        setError('An error occurred while submitting the order. Please try again.');
      }
    },
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  const renderFormFields = () => (
    <Grid container spacing={3}>
      {formFields.map(({ name, label, placeholder, helperText, gridWidth }) => (
        <Grid item xs={12} sm={gridWidth} key={name}>
          <FormTextField
            name={name}
            label={label}
            formik={formik}
            required
            placeholder={placeholder}
            helperText={helperText}
          />
        </Grid>
      ))}
    </Grid>
  );

  const radioGroups = [
    {
      name: 'mobileSubscription',
      label: 'Mobilabonnemang',
      options: subscriptionOptions,
    },
    {
      name: 'mobileData',
      label: 'Mobildata',
      options: dataOptions,
    },
    {
      name: 'phone',
      label: 'Telefon',
      options: phoneOptions,
    },
  ];

  const renderRadioGroups = () => (
    radioGroups.map(({ name, label, options }) => (
      <Box key={name} sx={{ mt: 3, p: 2, bgcolor: theme.palette.background.default, borderRadius: 1 }}>
        <FormRadioGroup
          name={name}
          label={label}
          options={options}
          formik={formik}
        />
      </Box>
    ))
  );

  return (
    <Box component="form" noValidate onSubmit={formik.handleSubmit}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {renderFormFields()}
      {renderRadioGroups()}
      <Box sx={{ mt: 4 }}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={!currentUser || submissionStatus === 'submitting'}
          sx={{ 
            bgcolor: theme.palette.primary.main, 
            color: 'white', 
            '&:hover': { 
              bgcolor: theme.palette.primary.dark 
            },
            '&:disabled': {
              bgcolor: theme.palette.action.disabledBackground,
              color: theme.palette.action.disabled
            }
          }}
        >
          {submissionStatus === 'submitting' ? 'Skickar...' : 'Beställ'}
        </Button>
      </Box>
    </Box>
  );
};

OrderEquipmentForm.propTypes = {
  onSubmitSuccess: PropTypes.func.isRequired,
};

export { OrderEquipment, OrderEquipmentForm };