// src/components/common/DataGridStyled.js
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import theme from '../../theme';

/**
 * A styled component for the DataGrid from Material-UI.
 * 
 * This component customizes various parts of the DataGrid using the theme provided by Material-UI.
 * 
 * Customizations include:
 * - Column headers background color, text color, font size, and font weight.
 * - Column header title font weight.
 * - Alternating row background color for odd rows.
 * - Removal of the border around the DataGrid.
 * - Bottom border for each cell.
 * - Background color for the virtual scroller.
 * - Top border and background color for the footer container.
 * 
 * @param {object} theme - The theme object provided by Material-UI.
 * @returns {object} - The styles applied to the DataGrid component.
 */
const DataGridStyled = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    fontSize: 16,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiDataGrid-root': {
    border: 'none',
  },
  '& .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiDataGrid-virtualScroller': {
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default DataGridStyled;
